<template>
    <div>
        <div class="padding">
            <el-page-header :title="$t('返回')" @back="goBack" :content="$t('历史练习记录')">
            </el-page-header>
        </div>
        <el-card class="margin-t" shadow="never">
            <el-empty v-if="records.length == 0" :description="$t('暂无历史练习记录') + '!'"></el-empty>
            <div v-else class="document">
                <el-card v-for="(item, index0) in records" class="margin-b" :key="index0">
                    <div slot="header" class="flex flex-between">
                        <span class="mt bold">{{ item.day }}</span>
                        <span class="mt margin-r">{{$t('练习题数')}}: {{ item.count }} {{$t('题')}}</span>
                    </div>

                    <el-menu default-active="0" router>
                        <el-menu-item :id="type.tag" v-for="(type, index1) in item.items" :key="index1"
                            :index="'/main/record/detail?day=' + item.day + '&tag=' + type.tag" class="border br margin"
                            style="height:60px">
                            <div class="flex flex-between flex-v-center" style="height:100%">
                                <div class="flex flex-v-center">
                                    <el-image :src="$host + type.logo" style="width:40px;height: 40px;"></el-image>
                                    <span class="margin-x st1 bold">{{ type.tag }}</span>
                                </div>
                                <span class="mt">{{$t('已练')}}: {{ type.count }} {{$t('题')}}<i class="el-icon-arrow-right"></i></span>
                            </div>
                        </el-menu-item>
                    </el-menu>
                </el-card>

                <el-divider>{{$t('仅展示最近 6 个月的练习记录')}}</el-divider>
            </div>
        </el-card>
    </div>
</template>

<script>
export default {
    data() {
        return {
        };
    },

    created() {
        this.query();
    },

    computed:{
        records: function () {
            return this.$store.state.records
        },
    },

    methods: {
        goBack() {
            this.$router.back();
        },
        query() {
            this.$http.post("/api/source/record/mine").then((records) => {
                this.$store.commit("setRecords",records)
            })
        },
    },
};
</script>
<style scoped>
.document>>>.el-card__header {
    padding: 10px 20px;
}


.document>>>.el-divider__text{
    color: #aaa;
}
</style>
